import { ReplaySubject, Subject } from "rxjs";

/**
 * Represents a Get Request whose result has been stored on the local cache.
 */
export class CachedGetRequest<T> {
    public expiresAt: number;
    public isPermanent: boolean;
    public finishLoading: boolean;
    public response: T;
    public subject: Subject<T>;
    public url: string;
}