import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { WebSocketService } from 'src/app/services/communication/web-socket/webSocket.service';
import { AuthenticationService } from 'src/app/services/users/authentication/authentication.service';
import { environment } from 'src/environments/environment';
import { EnvironmentUtils } from 'src/environments/environment-utils';

@Component({
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {

  username: string = '';
  password: string = '';
  keepSessionAlive: boolean = false;
  language = environment.language;

  constructor(
    private authenticationService: AuthenticationService,
    private deviceService: DeviceDetectorService,
    private translate: TranslateService,
    private webSocketService: WebSocketService,
    private router: Router) 
  {
    this.keepSessionAlive = environment.keepSessionAliveByDefault;
  }

  ngOnInit() { }

  getSplashImageUrl(): string {
    return '/assets/images/' + environment.product.filePrefix + '_Splash.png';
  }

  /**
   * Sign in to the app
   */
  login() {
    let usernameValid: boolean = (this.username?.length > 0) ? true : false;
    let passValid: boolean = (this.username?.length > 0) ? true : false;

    if (usernameValid && passValid) {
      this.authenticationService.login(this.username, this.password, this.keepSessionAlive).subscribe(user => {
        // If the session is valid it goes to the dashboard
        if (user != null) {
          // Ensures it's disconnected from the websocket
          this.webSocketService.disconnect();
          this.webSocketService.connect()

          if (user.password_expiration && new Date(user.password_expiration) < new Date()) {
            this.router.navigateByUrl('/password');
          } else {
            // Connect to socket
            this.webSocketService.connect()

            if (this.deviceService.isMobile())
            this.router.navigateByUrl('/home-mobile', {
              replaceUrl: true
            });
          else
            this.router.navigateByUrl('/dashboard/home', {
              replaceUrl: true
            });
          }
        }
      });
    }
  }

  /**
   * Gets the name of the language
   */
  getLanguageName(language: string): string {
    return this.translate.instant('languages.' + language);
  }

  /**
   * Set the language app
   */
  setLanguage(language) {

    let isValidLanguage = EnvironmentUtils.isValidLanguage(this.translate, language);

    if (!isValidLanguage)
      return;

    this.language = language;

    EnvironmentUtils.saveLanguage(this.language);
    
    this.translate.use(this.language);
  }

  /**
   * Events KeyDown
   * @param event 
   */
  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === "Enter") {
      this.login();
    }
  }
}