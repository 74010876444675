/**
 * Represents the different types of WebSocket messages.
 */
 export enum WebSocketMessageType {
    Unknown = 0,
    ErrorMessage = 1,
    Chat = 2,
    ChatMessage = 3,
    RequiredActionMessage = 4,
    UserActionMessage = 5,
}