import { Observable } from 'rxjs';
import { LogEntry } from '../logEntry';

export abstract class LogPublisher {
    location: string;

    abstract log(entry: LogEntry):
        Observable<boolean>

    abstract clear(): Observable<boolean>;
}