/**
 * Represents the different types of actions that can be done within a WebSocket Chat Message.
 */
 export enum WebSocketChatMessageAction {
    Unknown = 0,
    SendMessage = 1,
    MarkMessageAsDelivered = 2,
    MarkMessageAsRead = 3,
    DeleteMessage = 4,
    UpdateMessage = 5
}