import { environment } from "src/environments/environment";
import { RequiredActions } from "./RequiredActions";
import { RequiredAction } from "./RequiredAction";

/**
 * Provides a way to store information related to the Required Actions that are currently loaded.
 */
export class RequiredActionsStorage {

    /**
        * Keeps a static field, so the client will detect new Required Actions from any component.
        */
    oldRequiredActions: RequiredAction[] = null;

    /**
     * Keeps a static field with the most recent Required Actions that were loaded.
     */
    requiredActions: RequiredActions = null;

    public static getInstance(): RequiredActionsStorage {
        
        if (environment.global.requiredActionsNew == null)
            environment.global.requiredActionsNew = new RequiredActionsStorage();
        
        return environment.global.requiredActionsNew as RequiredActionsStorage;
    }
}
