/**
 * Defines different types of ways that an element can be stored in the local cache.
 */
export enum CacheType {
    /**
     * Right now, it only stores the element during 5 seconds. 
     * 
     * In the future, the cache mechanism will store the element until it receives a notification 
     * through the WebSocket that the cache element has been invalidated.
     */
    Default = 0,

    /**
     * Stores the element in the cache during 5 minutes. 
     * If the element is still in cache and it's acquired again, then the expiration time is extended for another 5 minutes.
     */
    Extendable = 1,

    /**
     * The element is stored and never removed from the cache
     */
    Permanent = 2,
    
    /**
     * The element is not stored on the cache.
     */
    Disabled = 3,
}