/**
 * Represents the user that the backend needs when authenticating.
 */
export class AuthUser {
    constructor() {
        this.id = null;
        this.username = "";
        this.password = ""
    }
    id: number;
    username: string;
    password: string;
    refresh: string;
    token: string;
}