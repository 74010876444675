import { WebSocketChatMessageAction } from "./WebSocketChatMessageAction";
import { WebSocketMessage } from "../notificaction/WebSocketMessage";
import { WebSocketMessageType } from "../notificaction/WebSocketMessageType";
import { ChatMessage } from "./ChatMessage";

export class WebSocketChatMessage extends WebSocketMessage {
    action: WebSocketChatMessageAction;
    data: ChatMessage;

    constructor() {
        super();

        this.type = WebSocketMessageType.ChatMessage;
    }
}
