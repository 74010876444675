import { UserType } from "./UserType";
import { UserRole } from "./roles/UserRole";

/**
 * Represents a user that has been authenticated.
 */
export class UserBase {
    id: number;
    username: string;

    /**
     * It's only retrieved when the server generates a new password.
     */
    password: string;

    lastname: string;
    firstname: string;
    employeeId: number;
    patientId: number;
    
    is_active: boolean;
    last_login: string;

    /**
     * The role assigned to the user.
     */
    role: UserRole;

    /**
     * The date and time when the user's password will expire.
     */
    password_expiration: string;

    /**
     * The type of user.
     */
    type: UserType;

    /**
     * The number of active connections that this user has.
     */
    connections: number;

    static isEmployee(user: UserBase): boolean {
        return user != null && user.employeeId != null && user.employeeId != undefined && user.employeeId > 0;
    }

    static isPatient(user: UserBase): boolean {
        if (this.isEmployee(user))        
            return false;

        return user != null && user.patientId != null && user.patientId != undefined && user.patientId > 0;
    }
}