import { TranslateService } from '@ngx-translate/core';
import { DeviceInfo } from 'ngx-device-detector';
import { User } from 'src/app/models/users/User';
import { Utilities } from "src/app/utilities/utilities";
import { environment } from "./environment";
import { RequiredActionsStorage } from 'src/app/models/users/notificaction/RequiredActionsStorage';

export const cookies = {
    language: 'language',
    accessToken: 'accessToken', // Represents the unique identifier of the current session.
    refreshToken: 'refreshToken', // Token used to request a new Access Token without forcing user authentication.
    keepSessionAlive: 'keepSessionAlive' // It's only used to preserved the default preference of the user to keep the session alive or not.
}

export class EnvironmentUtils {

    /**
     * Clears any global variable
     */
    static clearGlobalVariables() {
        environment.global.localConfiguration = null;
        environment.global.requiredActionsNew = new RequiredActionsStorage();
    }

    static restorePreferences() {
        // Restores the preferences that were stored in cookies
        let language = Utilities.getCookie(cookies.language);

        if (language != undefined && language != null)
            environment.language = language;

        environment.user = this.getUser();
        environment.keepSessionAliveByDefault = Utilities.getCookie(cookies.keepSessionAlive) === 'true';
    }

    static isValidLanguage(translate: TranslateService, languageToCheck: string): boolean {
        let isValidLanguage = false;
        let languages = translate.getLangs();

        for (let language of languages) {
            if (languageToCheck == language) {
                isValidLanguage = true;
                break;
            }
        }

        return isValidLanguage;
    }

    static saveLanguage(language: string) {
        // Updates the selected language.
        environment.language = language;

        // Saves a cookie with the new preferences
        Utilities.setCookie(cookies.language, language);
    }

    /**
     * Gets the current User that was saved in the cookies.
     * @returns The current User that was saved in the cookies.
     */
    static getUser(): User {
        let accessToken = Utilities.getCookie(cookies.accessToken);
        let refreshToken = Utilities.getCookie(cookies.refreshToken);

        if ((accessToken == undefined || accessToken == null) && (refreshToken == undefined || refreshToken == null))
            return null;

        let user = new User();
        user.accessToken = accessToken;
        user.refreshToken = refreshToken;

        return user;
    }

    /**
     * Saves the current User in the cookies.
     * 
     * @param user User to save in the local cookies. 
     */
    static saveUser(user: User) {
        let access: string;
        let refresh: string;

        if (user == null) {
            access = null;
            refresh = null;
        } else {
            access = user.accessToken;
            refresh = user.refreshToken;

            if (refresh != undefined && refresh != null && refresh.length > 0)
                Utilities.setCookie(cookies.keepSessionAlive, 'true', 7);
            else
                Utilities.setCookie(cookies.keepSessionAlive, null, 7);
        }

        // Updates the current user
        environment.user = user;

        // Clears global variables
        this.clearGlobalVariables();
        
        // Initializes configurations
        Utilities.initializeConfiguration();

        // Saves the cookies with the new preferences.
        Utilities.setCookie(cookies.accessToken, access);
        Utilities.setCookie(cookies.refreshToken, refresh);
    }

    /**
     * Get device info
     * @returns 
     */
    static getDeviceInfo(): DeviceInfo {
        return environment.deviceInfo;
    }

    /**
     * Set device info
     * @param deviceInfo 
     * @returns 
     */
    static setDeviceInfo(deviceInfo: DeviceInfo) {
        return environment.deviceInfo = deviceInfo;
    }
}