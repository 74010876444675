import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../models/users/User';
import { CachedGetRequest } from './CachedRequest';
import { backgroundRequest } from './httpClientInterceptor';
import { LogService } from './logger/log.service';
import { CacheType } from '../models/CacheType';
import { ExportableField } from '../models/reports/ExportableField';

@Injectable({
  providedIn: 'root'
})

/**
 * Provides a base/abstract class from which all services should inherit.
 * 
 * This class ensures that all the HttpRequests sent to the REST API include the required Headers, Parameters and authentication information.
 */
export abstract class BaseService {

  /**
   * The number of milliseconds to keep a request in the cache WHILE it's being loaded.
   */
  readonly EXPIRATION_TIME_WHILE_LOADING = 60000;

  /**
   * The default number of milliseconds to keep a request in the cache AFTER it has been loaded.
   * 
   * 5 seconds
   */
  readonly DEFAULT_EXPIRATION_TIME = 5000;

  /**
   * The number of milliseconds to keep a request in the cache AFTER the request has been loaded and when using "EXTENDABLE" mode.
   * 
   * 5 minutes
   */
  readonly EXTENDABLE_EXPIRATION_TIME = 5 * 60000;

  /**
   * The parameter value that must be send when requesting root's children.
   */
  public static root = 'root';

  /**
   * Map that stores the GET requests that were sent and need to be cached.
   */
  private static cachedGetRequests: Map<string, any> = new Map<string, any>();

  /**
   * Initializes a new instance of the BaseService class.
   * 
   * @param http        The HttpClient instance that will handle Http Requests.
   * @param baseServiceName The name of the remote service that will be used when constructing the URL.
   */
  constructor(
    protected log: LogService,
    protected http: HttpClient,
    @Inject(String) protected baseServiceName: string) {
  }

  /**
   * Gets the base service defined in the specified url.
   *
   * @param url The URL from which the base service will be obtained.
   * @returns The base service defined in the specified url.
   */
  public static getBaseServiceName(url: string): string {
    let end = url.indexOf('?');
    let service: string;

    // Ensures it's a valid call to a REST API
    let restAPIUrl = BaseService.getRestAPIUrl();

    if (!url.startsWith(restAPIUrl))
      return url;

    if (end < 0)
      service = url.substring(restAPIUrl.length);
    else
      service = url.substring(restAPIUrl.length, end);

    // Locates the base name of the service that was invoked (this removes identifiers 
    // and other modifiers that could have been added when the request was constructed)
    let serviceParts = service.split('/');
    let serviceFound = "";

    for (let i = 0; i < serviceParts.length; i++) {

      let servicePart = serviceParts[i].trim();

      if (servicePart.length == 0)
        continue;

      let id = parseInt(serviceParts[i]);

      if (id > 0 || servicePart === '0')
        continue

      serviceFound += serviceParts[i] + '/';
    }

    return serviceFound;
  }

  /**
   * Converts the specified array into a string with a comma separated list.
   * 
   * @param values Array that contains the values that need to be converted into a comma separated list.
   */
  protected getCommaSeparatedListString(values: any[]): string {
    return (values?.length > 0) ? values.join(",") : ""
  }

  /**
   * Gets a string that representes a "From" date and that can be sent to a service.
   * 
   * @param date          The date that needs to be converted.
   * @param preserveHour  Boolean that indicates whether the hour should be cleared or maintained.
   * @returns A string representation of the date.
   */
  protected getFromDate(date: Date, preserveHour?: boolean): string {
    if (!(date instanceof Date))
      return date

    if (!preserveHour) {
      // Ensures it covers the whole day
      date.setHours(0);
      date.setMinutes(0);
    }

    // It never preserves seconds (this improves the performance of the local cache by avoiding repetitive requests to be sent with a difference of seconds)
    date.setSeconds(0);
    date.setMilliseconds(0);

    return date.toISOString();
  }

  /**
   * Gets a string that representes a "To" date and that can be sent to a service.
   * 
   * @param date          The date that needs to be converted.
   * @param preserveHour  Boolean that indicates whether the hour should be cleared or maintained.
   * @returns A string representation of the date.
   */
  protected getToDate(date: Date, preserveHour?: boolean): string {
    if (!(date instanceof Date))
      return date

    if (!preserveHour) {
      // Ensures it covers the whole day
      date.setHours(23);
      date.setMinutes(59);
    }

    // It never preserves seconds (this improves the performance of the local cache by avoiding repetitive requests to be sent with a difference of seconds)
    date.setSeconds(59);
    date.setMilliseconds(999);

    return date.toISOString();
  }

  /**
   * Gets the URL that connects with the Rest API.
   */
  private static getRestAPIUrl(): string {
    return environment.restAPI.protocol + environment.restAPI.server + environment.restAPI.url;
  }

  public getAuthorizationHeader(): string {
    let user: User = environment.user;
    let authorization;

    if (user == null || user.accessToken == undefined || user.accessToken == null || user.accessToken.length == 0)
      authorization = "";
    else
      authorization = "JWT " + user.accessToken;

    return authorization;
  }

  protected getCommonFilterParams(limit?: number, offSet?: number, search?: string, ordering?: string): HttpParams {
    let params = new HttpParams()

    if (limit > 0)
      params = params.append('limit', limit);

    if (offSet > 0)
      params = params.append('offset', offSet);

    if (search != undefined && search != null && search.length > 0)
      params = params.append('search', search);

    if (ordering != undefined && ordering != null && ordering.length > 0)
      params = params.append('ordering', ordering);

    return params;
  }

  protected getExportableFieldsIds(exportableFields: ExportableField[]): string {
    let ids = "";

    if (exportableFields != null) {
      for (let i = 0; i < exportableFields.length; i++) {
        ids += exportableFields[i].id;

        if (i + 1 < exportableFields.length)
          ids += ",";
      }
    }

    return ids;
  }

  /**
   * When downloading a file, it gets the filename from the "content-disposition" header.
   * 
   * @param response The response obtained from the server.
   * 
   * @returns The name of the file that has being downloaded.
   */
  protected getFileNameFromResponseHeader(response: HttpResponse<Blob>): string {

    // Extract content disposition header
    let contentDisposition = response.headers.get('content-disposition');

    // Extract the file name
    let filename = contentDisposition
      .split(';')[1]
      .split('filename')[1]
      .split('=')[1]
      .trim()
      .match(/"([^"]+)"/)[1];

    return filename;
  }

  /**
   * Gets the Headers that the remote service needs to provide a response.
   * 
   * @param contentType Optional parameter with the type of content of the current request.
   */
  protected getHeaders(contentType?: string): HttpHeaders {
    if (contentType == null || contentType == undefined)
      contentType = 'application/json';

    const headerDict = {
      'Accept': 'application/json',
      'Accept-Language': environment.language,
      'Authorization': this.getAuthorizationHeader(),
      'Content-Type': contentType,
    }

    return new HttpHeaders(headerDict);
  }

  protected getParentParameter(parent?: string): string {
    if (parent == undefined || parent == "")
      return "parent=" + BaseService.root;

    return "parent=" + parent;
  }

  /**
   * Gets the Request Options that contains all the modifiers needed to download a file.
   * 
   * @param contentType     The expected content-type.
   * @param processResponse true to indicate that the entire response should be process and not only its content.
   * @returns The Request Options that contains all the modifiers needed to download a file.
   */
  protected getFileRequestOptions(contentType: string, processResponse?: boolean, background?: boolean, cacheType?: CacheType): any {

    // Ensures that it will process the entire response and not only its content
    let observe = processResponse ? 'response' : undefined;

    let requestOptions = {
      responseType: 'arraybuffer' as 'json',
      headers: new HttpHeaders({
        'Authorization': this.getAuthorizationHeader(),
      }),
      context: null,
      observe: observe
    };

    if (contentType != null && contentType != undefined)
      requestOptions.headers.append('Content-Type', contentType);

    if (background != null && background != undefined && background == true)
      requestOptions.context = backgroundRequest()

    // The local cache is disabled by default when loading files
    if (cacheType == null || cacheType == undefined)
      cacheType = CacheType.Disabled;

    return requestOptions;
  }

  /**
   * Gets the Request Options that contains all the modifiers needed for each request.
   * 
   * @param contentType Optional parameter with the type of content of the current request.
   * @returns The Request Options that contains all the modifiers needed for each request.
   */
  protected getRequestOptions(contentType?: string, background?: boolean): Object {
    let requestOptions = {
      headers: this.getHeaders(contentType),
      context: null
    }

    if (background != null && background != undefined && background == true)
      requestOptions.context = backgroundRequest()

    return requestOptions;
  }

  /**
   * Gets the URL that needs to be used to call the remote service.
   */
  protected getUrl(path: string, parameters: string): string {
    let serviceName;

    if (this.baseServiceName == undefined || this.baseServiceName == null || this.baseServiceName.length == 0)
      serviceName = "";
    else
      serviceName = this.baseServiceName + "/";

    if (path != undefined)
      serviceName += path;

    let url = BaseService.getRestAPIUrl() + serviceName;

    if (parameters?.length > 0)
      url += "?" + parameters;

    return url;
  }

  /**
   * Verifies if the specified response from a service has filtered enabled or not.
   * 
   * @param data The response that was obtained.
   */
  protected parseFilteredResults(data: any): any {
    // If there is a filter enabled, then the data structure is different
    if (data.results != undefined && data.results != null)
      return data.results;

    return data;
  }

  /**
   * Prepares and sends a DELETE request to the REST API.
   * 
   * @param path        Optional path that should be included on this specific call.
   * @param parameters  Optional parameters that should be included on this specific call.
   */
  protected sendDeleteRequest<T>(path?: string, parameters?: string): Observable<T> {
    let url = this.getUrl(path, parameters);
    let requestOptions = this.getRequestOptions();

    let subject = new Subject<T>();

    this.http.delete<T>(url, requestOptions).subscribe(
      {
        next: (response) => {

          // Forces all non-permanent cache entries to be removed
          BaseService.cleanCache(true);

          subject.next(response);
          subject.complete();
        },
        complete: () => {

          // Forces all non-permanent cache entries to be removed
          BaseService.cleanCache(true);

          subject.complete();
        },
        error: (error) => {

          // Forces all non-permanent cache entries to be removed
          BaseService.cleanCache(true);

          // Notifies the error
          subject.error(error);
          subject.complete();
        }
      });

    return subject;
  }

  /**
   * Prepares and sends a GET request to the REST API.
   * 
   * @param path           Optional path that should be included on this specific call.
   * @param parameters     Optional parameters that should be included on this specific call.
   * @param background     Optional boolean thtat indicates if the request should show a "dialog" window or not.
   * @param cacheType      Optional parameter that indicates the modality to use to store the results in the local cache.
   */
  protected sendGetRequest<T>(path?: string, parameters?: string, background?: boolean, cacheType?: CacheType): Observable<T> {
    let url = this.getUrl(path, parameters);
    let requestOptions = this.getRequestOptions(null, background);

    return this.sendGetRequestInternal(url, requestOptions, cacheType);
  }

  /**
   * Prepares and sends a GET request to the REST API.
   * 
   * @param path           Optional path that should be included on this specific call.
   * @param parameters     Optional parameters that should be included on this specific call.
   * @param background     Optional boolean thtat indicates if the request should show a "dialog" window or not.
   * @param cacheType      Optional parameter that indicates the modality to use to store the results in the local cache.
   */
  protected sendGetRequestInternal<T>(url: string, requestOptions: Object, cacheType?: CacheType): Observable<T> {
    if (cacheType == null || cacheType == undefined)
      cacheType = CacheType.Default;

    // Verifies if the local cache is enabled or if this request can be stored on the local cache
    if (!environment.cacheRequests || cacheType == CacheType.Disabled)
      return this.http.get<T>(url, requestOptions);

    // Clears all the cache entries that have already expired
    BaseService.cleanCache(false);

    // Verifies if there is a request that was already sent that could be reused
    let cachedGetRequest: CachedGetRequest<T> = BaseService.cachedGetRequests.get(url);

    if (cachedGetRequest == undefined) {
      cachedGetRequest = new CachedGetRequest<T>();

      // Specifies the time that the entry will be kept in cache while it's being loaded
      cachedGetRequest.isPermanent = false;
      cachedGetRequest.expiresAt = new Date().getTime() + this.EXPIRATION_TIME_WHILE_LOADING;
      cachedGetRequest.subject = new Subject<T>();

      this.http.get<T>(url, requestOptions).subscribe({
        next: (response) => {
          // Updates the time that this cache entry will be valid
          if (cacheType == CacheType.Permanent) {
            cachedGetRequest.isPermanent = true;
            cachedGetRequest.expiresAt = 0;
          } else {
            cachedGetRequest.isPermanent = false;

            if (cacheType == CacheType.Extendable)
              cachedGetRequest.expiresAt = new Date().getTime() + this.EXTENDABLE_EXPIRATION_TIME;
            else
              cachedGetRequest.expiresAt = new Date().getTime() + this.DEFAULT_EXPIRATION_TIME;
          }

          cachedGetRequest.finishLoading = true;
          cachedGetRequest.response = response;

          cachedGetRequest.subject.next(response);
          cachedGetRequest.subject.complete();
        },
        complete: () => {
          cachedGetRequest.subject.complete();
        },
        error: (error) => {
          // Notifies the error
          cachedGetRequest.subject.error(error);
          cachedGetRequest.subject.complete();
        }
      });

      BaseService.cachedGetRequests.set(url, cachedGetRequest)
    }
    else {

      if (cachedGetRequest.finishLoading) {
        // console.log("Reusing already loaded entry: " + url);

        if (cacheType == CacheType.Extendable)
          cachedGetRequest.expiresAt = new Date().getTime() + this.EXTENDABLE_EXPIRATION_TIME;

        return new Observable<T>(subscriber => {
          subscriber.next(cachedGetRequest.response);
          subscriber.complete();
        });
      } else {
        // console.log("Waiting for another request to finish: " + url);
      }
    }

    return cachedGetRequest.subject;
  }

  protected sendGetRequestWithFilter<T>(path?: string, parameters?: string, background?: boolean, cacheType?: CacheType): Observable<T> {

    let wrapResult = new Observable<T>(subscriber => {
      this.sendGetRequest<T>(path, parameters, background, cacheType).subscribe(
        {
          next: (data) => {
            let result: T = this.parseFilteredResults(data);

            // Notifies that the results have been received
            subscriber.next(result);
            subscriber.complete();
          },
          complete: () => {
            subscriber.complete();
          },
          error: (error) => {
            // Notifies the error
            subscriber.error(error);
            subscriber.complete();
          }
        });
    });

    return wrapResult;
  }

  /**
   * Prepares and sends a POST request to the REST API.
   * 
   * @param path          Optional path that should be included on this specific call.
   * @param urlParameters Optional parameters that should be included on this specific call.
   * @param body          Optional parameter that contains the body that needs to be sent.
   * @param contentType   Optional parameter with the type of content of the current request.
   */
  protected sendPostRequest<T>(path?: string, urlParameters?: string, body?: any, contentType?: string, background?: boolean): Observable<T> {
    let url = this.getUrl(path, urlParameters);
    let requestOptions = this.getRequestOptions(contentType, background);

    let subject = new Subject<T>();

    this.http.post<T>(url, body, requestOptions).subscribe(
      {
        next: (response) => {

          // Forces all non-permanent cache entries to be removed
          BaseService.cleanCache(true);

          subject.next(response);
          subject.complete();
        },
        complete: () => {

          // Forces all non-permanent cache entries to be removed
          BaseService.cleanCache(true);

          subject.complete();
        },
        error: (error) => {

          // Forces all non-permanent cache entries to be removed
          BaseService.cleanCache(true);

          // Notifies the error
          subject.error(error);
          subject.complete();
        }
      });

    return subject;
  }

  /**
   * Prepares and sends a PUT request to the REST API.
   * 
   * @param path        Optional path that should be included on this specific call.
   * @param parameters  Optional parameters that should be included on this specific call.
   * @param body        Optional parameter that contains the body that needs to be sent.
   */
  protected sendPutRequest<T>(path?: string, parameters?: string, body?: any, contentType?: string, background?: boolean): Observable<T> {
    let url = this.getUrl(path, parameters);
    let requestOptions = this.getRequestOptions(contentType, background);

    let subject = new Subject<T>();

    this.http.put<T>(url, body, requestOptions).subscribe(
      {
        next: (response) => {

          // Forces all non-permanent cache entries to be removed
          BaseService.cleanCache(true);

          subject.next(response);
          subject.complete();
        },
        complete: () => {

          // Forces all non-permanent cache entries to be removed
          BaseService.cleanCache(true);

          subject.complete();
        },
        error: (error) => {

          // Forces all non-permanent cache entries to be removed
          BaseService.cleanCache(true);

          // Notifies the error
          subject.error(error);
          subject.complete();
        }
      });

    return subject;
  }

  /**
   * Removes all the cache entries that have already expired.
   * 
   * @param forceClean true to clean all entries that are not permanenet; false, to only remove those entries that have expired
   */
  private static cleanCache(forceClean: boolean) {

    let keysToRemove = [];
    let now = new Date().getTime();

    // Identifies the cache entries that have already expired
    BaseService.cachedGetRequests.forEach((value: any, key: string) => {
      if (value.isPermanent)
        return;

      if (forceClean || now >= value.expiresAt)
        keysToRemove.push(key);
    });

    for (let i = 0; i < keysToRemove.length; i++) {
      let key = keysToRemove[i];
      BaseService.cachedGetRequests.delete(key);
    }
  }
}