import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientInterceptor } from './services/httpClientInterceptor';
import { LogService } from './services/logger/log.service';
import { LogPublishersService } from './services/logger/publishers/logPublishersService';

import { registerLocaleData } from '@angular/common';
import localeES from '@angular/common/locales/es';
import localeEN from '@angular/common/locales/en';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
registerLocaleData(localeES);
registerLocaleData(localeEN);

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

/** Injector generic for the app */
export let AppInjector: Injector;

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        IonicModule.forRoot({innerHTMLTemplatesEnabled: true}),
        AppRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        BrowserAnimationsModule
    ],
    providers: [
        // TODO: ¿Qué hacen estos providers?
        StatusBar,
        SplashScreen,
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy,
        },
        LogService,
        LogPublishersService,
        {
            // Declares the HttpClientInterceptor that will handle communication errors and connection progress.
            provide: HTTP_INTERCEPTORS,
            useClass: HttpClientInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { 
    constructor(private injector: Injector) {
        AppInjector = this.injector;
      }
}