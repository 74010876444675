import { UserBase } from "./UserBase";

/**
 * Represents a user that has been authenticated.
 */
export class User
    extends UserBase {
        
    /**
     * Represents the unique identifier of the current session.
     */
    accessToken: string;

    /**
     * Token used to request a new Access Token without forcing user authentication.
     */
    refreshToken: string;
}