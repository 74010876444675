import { WebSocketMessage } from "../notificaction/WebSocketMessage";
import { WebSocketMessageType } from "../notificaction/WebSocketMessageType";
import { Chat } from "./Chat";
import { WebSocketChatAction } from "./WebSocketChatAction";

export class WebSocketChat extends WebSocketMessage {
    action: WebSocketChatAction;
    data: Chat;

    constructor() {
        super();

        this.type = WebSocketMessageType.Chat;
    }
}
