import { Injectable } from '@angular/core';
import { LogConsole } from './logConsole';
import { LogLocalStorage } from './logLocalStorage';
import { LogPublisher } from './logPublisher';

@Injectable()
export class LogPublishersService {
    constructor() {
        // Build publishers arrays
        this.buildPublishers();
    }

    // Public properties
    publishers: LogPublisher[] = [];

    // Build publishers array
    buildPublishers(): void {
        let logConsole = new LogConsole();
        let localStorage = new LogLocalStorage();

        // Create instance of LogConsole Class
        this.publishers.push(logConsole);

        // Disabled for now
        //this.publishers.push(localStorage);
    }
}