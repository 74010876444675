import { of, Observable } from "rxjs";
import { LogEntry } from "../logEntry";
import { LogPublisher } from "./logPublisher";

export class LogConsole
    extends LogPublisher {

    log(entry: LogEntry): Observable<boolean> {
        // Log to console
        console.log(entry.buildLogString());
        return of(true);
    }

    clear(): Observable<boolean> {
        console.clear();
        return of(true);
    }
}