import { productProperties } from "./productProperties";

export const environment = {
  language: null,
  product: productProperties,
  production: true,
  restAPI: {
    protocol: 'https://',
    server: 'prod.backend.uner.indalcore.com', // Pre-production: 'dev.backend.uner.indalcore.com', // Old Production: 'backend.uner.indalcore.com',
    url: '/'
  },
  user: null,
  keepSessionAliveByDefault: false,
  deviceInfo: null,
  cacheRequests: true,
  refreshTimes: {
    appointments: 5 * 60 * 1000, // Every 5 minutes
  },
  warnings: null,
  global: {
    /**
     * Provides a way to keep in memory the "configuration" object that is stored in the local storage.
     * This helps in cases when a user opens multiple tabs and has different settings on each one of them.
     * 
     * Each tab will save to the local storage the most recent setting, but the  "environment.global.localConfiguration" 
     * instance will keep the particular values stored on each tab.
     */
    localConfiguration: null,
    requiredActionsNew: null,
    fileSizeLimit: 10 * 1024 * 1024 // 10MB
  },
};
