import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'src/environments/environment';
import { EnvironmentUtils } from 'src/environments/environment-utils';
import { WebSocketService } from './services/communication/web-socket/webSocket.service';
import { AuthenticationService } from './services/users/authentication/authentication.service';
import { Utilities } from './utilities/utilities';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})

export class AppComponent implements OnDestroy {
  /**
   * Constructor of class
   * @param platform 
   * @param router 
   * @param splashScreen 
   * @param statusBar 
   * @param WebSocketService 
   * @param translate 
   * @param authenticationService 
   * @param deviceDetectorService 
   */
  constructor(
    private platform: Platform,
    private router: Router,

    private splashScreen: SplashScreen,
    private statusBar: StatusBar,

    private webSocketService: WebSocketService,
    private translate: TranslateService,
    private authenticationService: AuthenticationService,
    private deviceDetectorService: DeviceDetectorService)
  {
    this.initializeApp();
  }

  initializeApp() {
    // Configures the platform
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });

    // Loads all the languages that are available
    // For now, it only supports Spanish
    this.translate.addLangs(['es']); //'en']);

    let defaultLanguage = 'es';
    let language = defaultLanguage;

    // Loads the browser's language
    if (language == undefined || language == null)
      language = this.translate.getBrowserLang()

    // Ensures that the language defined in the environment variable is valid
    let isValidLanguage = EnvironmentUtils.isValidLanguage(this.translate, language);

    if (isValidLanguage) {
      environment.language = language;
      defaultLanguage = language;
    }

    // Sets the default language that the application should use.
    this.translate.setDefaultLang(defaultLanguage);

    // Set device used
    EnvironmentUtils.setDeviceInfo(this.deviceDetectorService.getDeviceInfo());

    // Every session clear filter
    Utilities.setConfigurationFilter('maintenance-list', null)
    Utilities.setConfigurationFilter('employee-leave-all', null)
    Utilities.setConfigurationFilter('employee-leave-list', null)
    Utilities.setConfigurationFilter('employee-task-list', null)
    Utilities.setConfigurationFilter('non-working-day-list', null)
    Utilities.setConfigurationFilter('patient-list', null)
    Utilities.setConfigurationFilter('employee-list', null)
    Utilities.setConfigurationFilter('leave-all', null)
    Utilities.setConfigurationFilter('indicators', null)
    Utilities.setConfigurationFilter('items-list', null)
    Utilities.setConfigurationFilter('quote-list', null)
    Utilities.setConfigurationFilter('derivation-list', null)

    // Login:
    if (this.authenticationService.isAuthenticated()) {
      this.authenticationService.verifySession().subscribe(user => {
        // If the session is valid it goes to the dashboard
        if (user != null) {
          if (user.password_expiration && new Date(user.password_expiration) < new Date()) {
            this.router.navigateByUrl('/password');
          } else {
            // Connect to socket
            this.webSocketService.connect()

            if (this.deviceDetectorService.isMobile())
              this.router.navigateByUrl('/home-mobile');
            else
              this.router.navigateByUrl('/dashboard');
          }
        } else
          this.router.navigateByUrl('/login');
      });
    } else {
      this.router.navigateByUrl('/login');
    }
  }

  /**
   * On destroy component
   */
  ngOnDestroy(): void {
    this.webSocketService.disconnect()
  }
}