import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { ChatsComponent } from 'src/app/components/dashboard/chats/chats.component';
import { RequiredActionsComponent } from 'src/app/components/dashboard/required-actions/required-actions.component';
import { WebSocketUserAction } from 'src/app/models/users/WebSocketUserAction';
import { Chat } from 'src/app/models/users/chat/Chat';
import { ChatMessage } from 'src/app/models/users/chat/ChatMessage';
import { ChatMessageStatus } from 'src/app/models/users/chat/ChatMessageStatus';
import { WebSocketChat } from 'src/app/models/users/chat/WebSocketChat';
import { WebSocketChatAction } from 'src/app/models/users/chat/WebSocketChatAction';
import { WebSocketChatMessage } from 'src/app/models/users/chat/WebSocketChatMessage';
import { WebSocketChatMessageAction } from 'src/app/models/users/chat/WebSocketChatMessageAction';
import { WebSocketMessage } from 'src/app/models/users/notificaction/WebSocketMessage';
import { WebSocketMessageType } from 'src/app/models/users/notificaction/WebSocketMessageType';
import { WebSocketRequiredAction } from 'src/app/models/users/notificaction/WebSocketRequiredAction';
import { environment } from 'src/environments/environment';
import { BaseService } from '../../base.service';
import { HttpClientInterceptor } from '../../httpClientInterceptor';
import { LogService } from '../../logger/log.service';
import { EnvironmentUtils } from 'src/environments/environment-utils';
import { Router } from '@angular/router';
import { RequiredAction } from 'src/app/models/users/notificaction/RequiredAction';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService extends BaseService {

  /**
   * Constructor of class
   * @param log 
   * @param http 
   */
  constructor(
    protected httpClientInterceptor: HttpClientInterceptor,
    private translate: TranslateService,
    protected log: LogService,
    http: HttpClient,
    private router: Router) {
    super(log, http, "v2");

    this.connect()
  }

  //#region Methods

  public connect(): void {
    let service = this

    if ((!this.socket) && environment.user && environment.user.id > 0) {
      let protocol = 'wss://'
      if (location.protocol !== 'https:')
        protocol = 'ws://'

      this.socket = new WebSocket(protocol + environment.restAPI.server + environment.restAPI.url + 'v2/ws/' + environment.user.id + '/?token=' + environment.user.accessToken);

      this.socket.onopen = function (e) {
        // TODO: ¿deberíamos de enviar un mensaje para validar la sesión?
        service.waitTimeBeforeReconnect = 250;
      };

      this.socket.onerror = function (e) {
        service.disconnect();        
      }

      this.socket.onclose = function (e) {
        service.disconnect();

        // It adds 250 ms to the wait time on each reattempt, by doing this it avoids to overflow the server with requests
        service.waitTimeBeforeReconnect += 250;

        setTimeout(function() {
          service.connect();
        }, Math.min(5000, service.waitTimeBeforeReconnect))
      }

      this.socket.onmessage = function (e) {
        let message = JSON.parse(e.data) as WebSocketMessage;

        if (message.type == WebSocketMessageType.ErrorMessage && message.data != null) {

          service.httpClientInterceptor.showErrorMessage(message.data.action, message.data.detail);

          if (message.data.session_closed) {
            service.disconnect();
            
            // Removes any user information from the cookies
            EnvironmentUtils.saveUser(null);

            // Go to login page
            service.router.navigateByUrl('/login');
          }

          return;
        }

        let notificationTitle = null;
        let notificationMessage = null;

        if (message.type == WebSocketMessageType.Chat && message.data != null) {

          if (!service.chatsComponentVisible && service.chatsComponent != null) {
            notificationMessage = service.chatsComponent.getChatName(message.data);

            if (message.action == WebSocketChatAction.CreateChat)
              notificationTitle = "Nuevo Chat";

            if (message.action == WebSocketChatAction.DeleteChat)
              notificationTitle = "Chat Eliminado";

            if (message.action == WebSocketChatAction.UpdateChat)
              notificationTitle = "Chat Modificado";
          }

          service.subjectChat.next(message);
        } else if (message.type == WebSocketMessageType.ChatMessage && message.data != null) {
          service.subjectChatMessage.next(message);

          if (!service.chatsComponentVisible && service.chatsComponent != null && message.action == WebSocketChatMessageAction.SendMessage || message.action == WebSocketChatMessageAction.UpdateMessage) {
            notificationTitle = service.chatsComponent.getNamePatientEmployee(message.data);
            notificationMessage = message.data.text;
          }
        } else if (message.type == WebSocketMessageType.RequiredActionMessage) {
          service.subjectRequiredAction.next(message);

          // Instructs the RequiredAction component to process this event
          if (!service.requiredActionsComponentVisible && service.requiredActionsComponent != null)
            service.requiredActionsComponent.checkNewRequiredActions();
        } else if (message.type == WebSocketMessageType.UserActionMessage) {
          service.subjectUserAction.next(message);
        }
        else
          service.subject.next(message);

        // Show Notification. It ignores messages that were generated by the current user
        if (notificationTitle != null && message.owner_user_id != environment.user?.id)
          service.showNotify(notificationTitle, notificationMessage);
      }
    }
  }

  /**
   * Send a message 
   * @param message 
   */
  public send(message: WebSocketMessage): void {
    try {
      this.socket.send(JSON.stringify(message));
    } catch (ex) {
      let header = this.translate.instant('services.errorHeader');
      let message = this.translate.instant('services.defaultErrorMessage');

      this.httpClientInterceptor.showErrorMessage(header, message);
      console.error(ex)
    }
  }

  /**
   * Get a message
   * @returns 
   */
  public onMessage(): any {
    return this.subject.asObservable();
  }

  /**
   * Close the socket
   */
  public disconnect(): void {
    try {
      if (this.socket)
        this.socket.close();
    } catch (ex) {
      // Best effort to close socket
    }

    this.socket = undefined;
  }

  //#endregion


  //#region Chat

  private subjectChat = new Subject<WebSocketChat>();
  private subjectChatMessage = new Subject<WebSocketChatMessage>();
  private subjectRequiredAction = new Subject<WebSocketRequiredAction>();
  private subjectRequiredActionsLoad = new Subject<RequiredAction[]>();
  private subjectNotification = new Subject<Notification>();
  private subjectUserAction = new Subject<any>();

  /**
   * Get a message
   * @returns 
   */
  public onMessageChat(): Observable<WebSocketChat> {
    return this.subjectChat.asObservable();
  }

  /**
   * Get a message
   * @returns 
   */
  public onMessageChatMessage(): Observable<WebSocketChatMessage> {
    return this.subjectChatMessage.asObservable();
  }

  /**
   * Get a message of required action
   * @returns 
   */
  public onMessageRequiredAction(): Observable<WebSocketRequiredAction> {
    return this.subjectRequiredAction.asObservable();
  }


  /**
   * Get a message of required action
   * @returns 
   */
  public onRequiredActionsLoad(): Observable<RequiredAction[]> {
    return this.subjectRequiredActionsLoad.asObservable();
  }

  /**
   * 
   * @param actions 
   */
  public notifyRequiredActionsLoad(actions: RequiredAction[]) {
    this.subjectRequiredActionsLoad.next(actions)
  }


  /**
   * Called whenever a user performs an action that creates, deletes or updates an entity on the server.
   * 
   * @returns 
   */
  public onMessageUserAction(): Observable<WebSocketUserAction> {
    return this.subjectUserAction.asObservable();
  }

  /**
   * Return an observable notification as observable
   * @returns 
   */
  public onNotification(): Observable<Notification> {
    return this.subjectNotification.asObservable();
  }

  /**
   * Change status of messages chats
   * @param lastChatMessages 
   * @param expectedStatus 
   * @returns 
   */
  public changeChatMessagesStatus(lastChatMessages: ChatMessage[], expectedStatus: ChatMessageStatus) {
    if (lastChatMessages.length == 0)
      return;

    let lastChatMessage = lastChatMessages[lastChatMessages.length - 1];

    // Verifies if it is a message that needs to be marked as delivered
    if (!this._hasToChangeChatMessageStatus(lastChatMessage, expectedStatus))
      return;

    // Marks the messages as read
    let message = new WebSocketChatMessage();
    message.action = (expectedStatus == ChatMessageStatus.Delivered) ? WebSocketChatMessageAction.MarkMessageAsDelivered : WebSocketChatMessageAction.MarkMessageAsRead;
    message.user_id = environment.user.id;
    message.data = lastChatMessage;

    this.send(message);
  }

  /**
   * Delete a chat using websocket
   * @param chatGroup 
   */
  public deleteChat(chatGroup: Chat): void {
    // Sends a message through the WebSocket
    let message = new WebSocketChat();
    message.action = WebSocketChatAction.DeleteChat;
    message.data = chatGroup;
    message.user_id = environment.user.id;

    this.send(message);
  }

  isRequiredActionsComponentVisible(): boolean {
    return this.requiredActionsComponentVisible;
  }

  setChatsComponent(chatsComponent: ChatsComponent) {
    this.chatsComponent = chatsComponent;
    this.chatsComponentVisible = true;
  }

  setChatsComponentVisible(isVisible: boolean) {
    this.chatsComponentVisible = isVisible;
  }

  setRequiredActionsComponent(requiredActionsComponent: RequiredActionsComponent) {
    this.requiredActionsComponent = requiredActionsComponent;
    this.requiredActionsComponentVisible = true;
  }

  setRequiredActionsComponentVisible(isVisible: boolean) {
    this.requiredActionsComponentVisible = isVisible;
  }

  /**
   * Show notification
   * @param type 
   */
  showNotify(title: string, body: string) {
    let message = environment.product.name;

    if (title?.length > 0)
      message += " - " + title;

    let notificationOptions = null;

    if (body?.length > 0)
      notificationOptions = { body: body };

    if (!("Notification" in window)) {
      // Check if the browser supports notifications
      console.error("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      // Check whether notification permissions have already been granted;
      // if so, create a notification
      const notification = new Notification(message, notificationOptions);

      this.subjectNotification.next(notification)
    } else if (Notification.permission !== "denied") {
      // We need to ask the user for permission
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          const notification = new Notification(message, notificationOptions);

          this.subjectNotification.next(notification)
        }
      });
    }
  }

  /**
   * Verifies if it is a message that needs to be marked as delivered
   * @param chatMessage 
   * @param expectedStatus 
   * @returns 
   */
  private _hasToChangeChatMessageStatus(chatMessage: ChatMessage, expectedStatus: ChatMessageStatus): boolean {
    if (chatMessage.pending_status?.length > 0) {
      for (let i = 0; i < chatMessage.pending_status.length; i++) {
        let pending_status = chatMessage.pending_status[i];

        if (pending_status.user == environment.user.id && pending_status.status < expectedStatus)
          return true;
      }
    }

    return false;
  }

  //#endregion


  //#region Notifications

  public clearNotifications(): void {
    this.subjectNotification.next(null)
  }

  //#endregion


  //#region Properties

  private socket: WebSocket;
  private subject = new Subject<any>();

  private chatsComponent: ChatsComponent = null;
  private chatsComponentVisible: boolean = false;

  private requiredActionsComponent: RequiredActionsComponent = null;
  private requiredActionsComponentVisible: boolean = false;

  /**
   * The number of milliseconds to wait before attempting to reconnect.
   */
  private waitTimeBeforeReconnect: number;
  //#endregion
}