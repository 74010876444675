import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginPageModule } from '../app/pages/users/authentication/login/login.module'
import { AuthGuard } from '../app/helpers/auth.guard';

const routes: Routes = [
  // --- Public access pages --- 
  {
    // Redirects empty path to the login page.
    path: "",
    component: LoginPageModule,
    pathMatch: "full"
  },
  {
    // The Login page.
    path: "login",
    loadChildren: () => import('./pages/users/authentication/login/login.module').then(m => m.LoginPageModule)
  },

  // --- Authenticated pages --- 
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'home-mobile',
    loadChildren: () => import('./pages/dashboard/home-mobile/home-mobile.module').then((m) => { return m.HomeMobileModule; }),
    canActivate: [AuthGuard]
  },

  // --- Change password page --- 
  {
    path: 'password',
    loadChildren: () => import('./pages/users/authentication/password/password-routing.module').then(m => m.PasswordPageRoutingModule),
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [
    // TODO: Verificar si esta es la "Preloading" strategy que queremos seguir...
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
