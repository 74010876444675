import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, Subscriber } from 'rxjs';
import { User } from 'src/app/models/users/User';
import { AuthUser } from 'src/app/models/users/authentication/AuthUser';
import { AuthenticationResults } from 'src/app/models/users/authentication/AuthenticationResults';
import { environment } from 'src/environments/environment';
import { EnvironmentUtils } from 'src/environments/environment-utils';
import { BaseService } from '../../base.service';
import { LogService } from '../../logger/log.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService extends BaseService {

  /**
   * Keeps track if a session is already being restored
   */
  private restoringSession: boolean;

  constructor(
    protected log: LogService,
    private router: Router,
    http: HttpClient) {
    super(log, http, "");
  }

  finishedRestoringSession() {
    this.restoringSession = false;
  }

  isAuthenticated(): boolean {
    // Gets the current user that is stored in the cookies
    let user = EnvironmentUtils.getUser();

    return user != null && user.accessToken != null && user.accessToken != undefined && user.accessToken.length > 0;
  }

  isRestoringSession(): boolean {
    return this.restoringSession;
  }

  /**
   * Sign in to the app.
   * 
   * @param username Name of the user
   * @param password Password of the user
   * @param keepSessionAlive Indicates whether the user wants to keep the session always opened or not
   * 
   * @returns An Observable that will provide the user.
   */
  login(username: string, password: string, keepSessionAlive: boolean): Observable<User> {
    let result = new Observable<User>(subscriber => {

      let authUser = new AuthUser();
      authUser.username = username;
      authUser.password = password;

      this.authenticate(subscriber, "auth-jwt/", authUser, keepSessionAlive, false);
    });

    return result;
  }

  /**
   * Close the session
   */
  logOut() {
    // Clear the session variables
    EnvironmentUtils.saveUser(null);
  }

  /**
   * Refreshes the current session.
   * 
   * @returns An Observable that indicates if the session was successfully refreshed or not.
   */
  restoreSession(background: boolean): Observable<User> {
    let service = this

    // Gets the current user that is stored in the cookies
    let user: User = environment.user;
    let result = new Observable<User>(subscriber => {

      if (this.restoringSession) {
        // It's already restoring the session through another call
        return;
      }

      if (user == undefined || user == null || user.refreshToken == undefined || user.refreshToken == null || user.refreshToken.length == 0) {

        // No user, no session to restore
        // Go to login page
        this.router.navigateByUrl('/login');

        return;
      }

      // Ensures a session is not restored simultaneously with parallel requests
      this.restoringSession = true;

      let authUser = new AuthUser();
      authUser.refresh = user.refreshToken;

      this.authenticate(subscriber, "auth-jwt-refresh/", authUser, true, background).then(r => {
        // The session has been restored
        service.finishedRestoringSession();

        subscriber.next(r as User);
        subscriber.complete();
      })
    });

    return result;
  }

  /**
   * Verifies that the current session is still valid.
   * 
   * @returns An Observable that will provide the user.
   */
  verifySession(): Observable<User> {
    // Gets the current user that is stored in the cookies
    let user: User = environment.user;
    let result = new Observable<User>(subscriber => {

      if (user == undefined || user == null || user.accessToken == undefined || user.accessToken == null) {
        subscriber.next(null);
        subscriber.complete();
      } else {
        let authUser = new AuthUser();
        authUser.token = user.accessToken;

        this.authenticate(subscriber, "auth-jwt-verify/", authUser, true, true);
      }
    });

    return result;
  }

  /**
   * Provides a generic way to authenticate a user (login, verify and refresh).
   * 
   * @param subscriber The subscriber that will be notified upon completion.
   * @param serviceName The name of the service that will be invoked.
   * @param authUser The credentials/tokens that need to be sent.
   * @param keepSessionAlive Indicates whether the user wants to keep the session always opened or not
   */
  private authenticate(subscriber: Subscriber<User>, serviceName: string, authUser: AuthUser, keepSessionAlive: boolean, background?: boolean) {
    let service = this

    return new Promise((resolve) => {
      let authResult: Observable<AuthenticationResults> = service.sendPostRequest(serviceName, undefined, authUser, "application/json", background);
      authResult.subscribe(loginResults => {

        // Ensures the "refresh" token is preserved on those services that do not retrieve it
        if (keepSessionAlive && (loginResults.refresh == undefined || loginResults.refresh == null)) {
          loginResults.refresh = environment.user.refreshToken;
        }

        // It signals the subscribers
        let user = service.loadUser(loginResults, keepSessionAlive);
        subscriber.next(user);
        subscriber.complete();

        // resolve promise
        resolve(user)
      });
    })
  }

  /**
   * Loads the user information that was obtained from the authentication service.
   * 
   * @param loginResults The response obtained from the authentication service.
   * @param keepSessionAlive Indicates whether the user wants to keep the session always opened or not.
   * @returns An observable with a boolean that indicates whether the action was successfully completed or not.
   */
  private loadUser(loginResults: AuthenticationResults, keepSessionAlive: boolean): User {
    if (loginResults.access == undefined || loginResults.access == null || loginResults.access.length == 0)
      return null;

    let user = new User();
    user.accessToken = loginResults.access;

    if (keepSessionAlive)
      user.refreshToken = loginResults.refresh;

    user.id = loginResults.profile.id;
    user.username = loginResults.profile.username;
    user.firstname = loginResults.profile.firstname;
    user.lastname = loginResults.profile.lastname;
    user.employeeId = loginResults.profile.employeeId;
    user.patientId = loginResults.profile.patientId;
    user.is_active = loginResults.profile.is_active;
    user.last_login = loginResults.profile.last_login;
    user.role = loginResults.profile.role;
    user.password_expiration = loginResults.profile.password_expiration;

    // Saves the current user
    EnvironmentUtils.saveUser(user);
    
    // Notify changes on user
    this.subjectUser.next(user);

    environment.warnings = loginResults.warnings;

    return user;
  }


  //#region Observables

  private subjectUser = new Subject<User>();

  /**
   * Get a message
   * @returns 
   */
  public onSaveUser(): any {
    return this.subjectUser.asObservable();
  }

  //#endregion
}