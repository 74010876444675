import { Injectable } from '@angular/core';
import { LogEntry, LogLevel } from './logEntry';
import { LogPublisher } from './publishers/logPublisher';
import { LogPublishersService } from './publishers/logPublishersService';

@Injectable({
  providedIn: 'root'
})

export class LogService {

  level: LogLevel = LogLevel.All;
  logWithDate: boolean = true;
  publishers: LogPublisher[];

  constructor(private publishersService: LogPublishersService) {
    // Set publishers
    this.publishers = this.publishersService.publishers;

    this.clear();
  }

  debug(caller: object, msg: string, ...optionalParams: any[]) {
    this.writeToLog(caller, msg, LogLevel.Debug, optionalParams);
  }

  info(caller: object, msg: string, ...optionalParams: any[]) {
    this.writeToLog(caller, msg, LogLevel.Info, optionalParams);
  }

  warn(caller: object, msg: string, ...optionalParams: any[]) {
    this.writeToLog(caller, msg, LogLevel.Warn, optionalParams);
  }

  error(caller: object, msg: string, ...optionalParams: any[]) {
    this.writeToLog(caller, msg, LogLevel.Error, optionalParams);
  }

  fatal(caller: object, msg: string, ...optionalParams: any[]) {
    this.writeToLog(caller, msg, LogLevel.Fatal, optionalParams);
  }

  log(caller: object, msg: string, ...optionalParams: any[]) {
    this.writeToLog(caller, msg, LogLevel.All, optionalParams);
  }

  private clear() {
    for (let logger of this.publishers)
      logger.clear();
  }

  private shouldLog(level: LogLevel): boolean {
    let ret: boolean = false;

    if ((level >= this.level && level !== LogLevel.Off) || this.level === LogLevel.All) {
      ret = true;
    }

    return ret;
  }

  private writeToLog(caller: object, msg: string, level: LogLevel, params: any[]) {
    if (this.shouldLog(level)) {

      let entry: LogEntry = new LogEntry();
      entry.caller = caller != null ? caller.constructor.name : null;
      entry.message = msg;
      entry.level = level;
      entry.extraInfo = params;
      entry.logWithDate = this.logWithDate;

      for (let logger of this.publishers)
        logger.log(entry);
    }
  }
}