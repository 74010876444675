import { formatDate } from "@angular/common";

export class LogEntry {
    // Public Properties
    entryDate: Date = new Date();
    caller: string;
    message: string = "";
    level: LogLevel = LogLevel.Debug;
    extraInfo: any[] = [];
    logWithDate: boolean = true;

    buildLogString(): string {
        let ret: string = "";

        // Adds the date
        if (this.logWithDate)
            ret = formatDate(new Date(), "yyyy-MM-dd hh:mm:ss", "en-EN") + " - ";

        // Adds the caller
        if (this.caller != null)
            ret += "[" + this.caller + "] - ";

        // Adds the type
        ret += LogLevel[this.level];

        // Adds the message
        ret += " - " + this.message;

        // Adds any additional parameter
        if (this.extraInfo.length)
            ret += ": " + this.formatParams(this.extraInfo);

        return ret;
    }

    private formatParams(params: any[]): string {
        let ret: string = params.join(",");

        // Is there at least one object in the array?
        if (params.some(p => typeof p == "object")) {
            ret = "";

            // Build comma-delimited string
            for (let item of params)
                ret += JSON.stringify(item) + ",";
        }

        return ret;
    }
}

export enum LogLevel {
    All = 0,
    Debug = 1,
    Info = 2,
    Warn = 3,
    Error = 4,
    Fatal = 5,
    Off = 6
}