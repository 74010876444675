<ion-content no-padding no-margin>
  <div class="loginContent">
    <div class="loginDialog">
      <ion-img type="image/png" [src]="getSplashImageUrl()"></ion-img>
      <form #form="ngForm" (ngSubmit)="login()">
        <div class="loginFields">
          <div>
            <ion-item>
              <ion-input [(ngModel)]="username" name="username" type="username"
                placeholder="{{'authentication.username' | translate}}" ngModel required>
              </ion-input>
            </ion-item>
          </div>
          <div>
            <ion-item>
              <ion-input [(ngModel)]="password" name="password" type="password"
                placeholder="{{'authentication.password' | translate}}" ngModel required>
              </ion-input>
            </ion-item>
          </div>
          <div class="noBorders" style="margin-top: 25px;">
            <input id="keepSessionAlive" type="checkbox" [(ngModel)]="keepSessionAlive" name="keepSessionAlive" slot="start"/>
            <label for="keepSessionAlive">{{'authentication.keepSession' | translate}}</label>
          </div>
        </div>
        <div class="loginButtons">
          <ion-button size="large" type="submit" [disabled]="form.invalid" expand="block">
            <ion-label>{{'authentication.login' | translate}}</ion-label>
          </ion-button>
        </div>
      </form>
    </div>
    <div class="languages">
      <span [ngClass]="{'active': language === 'es'}" (click)="setLanguage('es')">Español</span>
      <span>|</span>
      <span [ngClass]="{'active': language === 'en'}">Inglés</span> <!-- Disabled for now: (click)="setLanguage('en')" -->
    </div>
  </div>
</ion-content>